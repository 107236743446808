<script setup lang="ts">

</script>

<template>
  <div class="box-border rd-8 bg-white uno-layer-components:w-full uno-layer-components:p-30">
    <div v-if="$slots.head" class="h-32 w-full flex items-center text-18 text-[--color-text-1] font-500">
      <slot name="head" />
    </div>
    <slot />
  </div>
</template>

<style scoped>
</style>
